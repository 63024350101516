<template>
  <v-card class="my-4">
    <v-card-text>
      <!-- <v-btn @click="forceSave()">FORCESAVE</v-btn> -->
      <v-row class="pt-2 pb-4">
        <v-col>
          <v-alert
            color="#F8BBD0"
            class="pt-4"
            text
            prominent
            icon="mdi-information-slab-circle"
          >
            <h3 style="color: #d81b60; font-weight: normal">
              Улирлаа сонгоод ҮНЭЛЭX товчийг дарж үнэлгээгээ оруулна.
            </h3>
            <span class="grey--text"
              >Xагас жилийн үнэлгээнүүдийг Үнэлгээ 2 гэж нэрлэнэ.</span
            >
            <span style="color: #d81b60">
              2-р xагас жилийн үнэлгээ2 дүн ЖИЛИЙН ЭЦСИЙН ДҮН болж
              xадгалагдана!</span
            >
          </v-alert>
        </v-col>
        <v-col cols="4">
          <!-- style="background-color: #fef7f9" -->
          <v-select
            style="background-color: yellow"
            :items="[
              {
                yearId: 1,
                name: preText + ' xичээлийн жил, 1-р xагас жил',
              },
              {
                yearId: 2,
                name: preText + ' xичээлийн жил, 2-р xагас жил',
              },
            ]"
            v-model="selectedHalfYear"
            label="Xагас жил"
            return-object
            item-text="name"
            item-value="yearId"
          >
          </v-select>
        </v-col>
      </v-row>
      <h3
        class="mb-4 blue--text font-weight-regular text-uppercase"
        v-if="selectedHalfYear"
      >
        {{ selectedHalfYear.name }}
      </h3>
      <v-progress-linear
        v-if="loading || filteredLessons.length == 0"
        color="red"
        height="6"
        indeterminate
      ></v-progress-linear>

      <v-data-table
        class="unelgee2table"
        :headers="headerNames"
        :items="filteredLessons"
        :items-per-page="-1"
        hide-default-footer
        hide-default-header
        item-key="uniqueId"
      >
        <template slot="header">
          <tr style="font-size: 13px; background-color: #ececec !important">
            <th
              class="text-center font-weight-normal"
              v-for="(header, hindex) in headerNames"
              :key="'header' + hindex"
              :style="header.style ? header.style : ''"
              :class="header.class ? header.class : ''"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:item.index="{ item }">
          <div class="text-center" @click="_print2(item)">
            {{ item.index }}
          </div>
        </template>
        <template v-slot:item.courseInfo.COURSE_NAME="{ item }">
          <div
            class="pl-2"
            style="white-space: nowrap; cursor: pointer"
            @click="_removeFromLesson(item)"
          >
            {{ item.courseInfo.COURSE_NAME }}
          </div>
        </template>
        <template v-slot:item.esisLessonType="{ item }">
          <div class="text-center">
            <small
              class="blue--text text-lowercase"
              v-if="
                item.courseInfo &&
                item.courseInfo.ENROLLMENT_CATEGORY == 'MANDATORY'
              "
              >Заавал</small
            >
            <small
              v-else-if="
                item.courseInfo &&
                item.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'
              "
              class="red--text text-lowercase"
              >Сонгон
            </small>
          </div>
        </template>
        <template v-slot:item.classGroups="{ item }">
          <span
            v-if="item.classGroups && !item.isCollected"
            style="font-size: 12pt"
          >
            <div
              class="text-center text-lowercase"
              elevation="0"
              v-for="(cgroup, cgindex) in item.classGroups"
              :key="cgroup.id + cgindex"
            >
              {{ cgroup.classGroupName }}
            </div>
          </span>
          <div
            v-if="item.isCollected && item.subClassGroups"
            style="font-size: 12pt; text-align: center"
          >
            <v-btn
              elevation="0"
              class="font-weight-bold border-radius-sm shadow-none py-1 px-2 mr-1 green lighten-3 ml-1"
              :height="28"
              :min-width="0"
              v-for="(cgroup, cgindex) in item.subClassGroups"
              :key="cgroup.id + cgindex"
              >{{
                cgroup.classGroups
                  ? cgroup.classGroups[0].classGroupFullName
                  : "-"
              }}</v-btn
            >
          </div>
        </template>

        <template v-slot:item.numberOfStudents="{ item }">
          <!-- <div class="text-center" v-if="item['numberOfStudents-' + teacherId]">
            {{ item["numberOfStudents-" + teacherId] }}
          </div> -->
          <div class="text-center">
            <span>
              <div v-if="item.students">
                {{ item.students.length }}
              </div>
              <div v-else-if="item.numberOfStudents">
                {{ item.numberOfStudents }}
              </div>
              <div v-else>0</div>
            </span>
          </div>
        </template>
        <template v-slot:item.approvedPercentage="{ item }">
          <div
            class="text-center"
            :class="_getConfirmedProcent(item) == '100%' ? 'green--text' : ''"
          >
            {{ _getConfirmedProcent(item) }}
          </div>
        </template>
        <template v-slot:item.approvedNumber="{ item }">
          <div
            class="text-center green--text"
            style="background: #e8f5e9; height: 100%"
          >
            <!-- {{
              item["year" + this.selectedHalfYear.yearId + "UnelgeeScores-" + teacherId]
                ? item["year" + this.selectedHalfYear.yearId + "UnelgeeScores-" + teacherId].approvedNumber
                : 0
            }} -->
            {{ getAnalystics(item, 1) }}
          </div>
        </template>
        <template v-slot:item.inProgressNumber="{ item }">
          <div
            class="text-center"
            style="color: #000; background-color: #fff4d4; height: 100%"
          >
            <!-- {{
              item["year" + this.selectedHalfYear.yearId + "UnelgeeScores-" + teacherId]
                ? item["year" + this.selectedHalfYear.yearId + "UnelgeeScores-" + teacherId].inProgressNumber
                : 0
            }} -->
            {{ getAnalystics(item, 2) }}
          </div>
        </template>
        <template v-slot:item.rejectedNumber="{ item }">
          <div
            class="text-center red--text"
            style="background: #ffebee; height: 100%"
          >
            <!-- {{
              item["year" + this.selectedHalfYear.yearId + "UnelgeeScores-" + teacherId]
                ? item["year" + this.selectedHalfYear.yearId + "UnelgeeScores-" + teacherId].rejectedNumber
                : 0
            }} -->
            {{ getAnalystics(item, 3) }}
          </div>
        </template>
        <template v-slot:item.sentNumber="{ item }">
          <div
            class="text-center blue--text"
            style="height: 100%; background-color: #e8e8ff"
          >
            <!-- {{
              item["year" + this.selectedHalfYear.yearId + "UnelgeeScores-" + teacherId]
                ? item["year" + this.selectedHalfYear.yearId + "UnelgeeScores-" + teacherId].sentNumber
                : 0
            }} -->
            {{ getAnalystics(item, 4) }}
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="text-center">
            <v-progress-linear
              v-if="item.studentLoading"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
            <v-btn
              v-else
              dark
              elevation="0"
              x-small
              class="bg-gradient-danger"
              @click="
                item.studentLoading = true;
                selectedLesson = Object.assign({}, item);
                triggerUnelgeeDialog = !triggerUnelgeeDialog;
              "
            >
              үнэлэх
            </v-btn>
          </div>
        </template>
        <template v-slot:item.finalGradeSavedToEsis="{ item }">
          <small class="green--text ml-2">{{
            item.finalGradeSavedToEsis ? "ESIS-д хадгалагдсан" : ""
          }}</small>
          <p
            style="font-size: 11px; line-height: 0.5"
            class="blue--text mb-2 mt-0 ml-2"
          >
            {{ item.finalGradeSavedToEsisAt | formatDate }}
          </p>
        </template>
        <template v-slot:item.finalGradeSavedToEsisAt="{ item }">
          <v-progress-linear
            v-if="!item.unelgee2Scores"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <div
            class="pl-2"
            style="width: 300px"
            v-else-if="
              item.finalGradeSavedToEsisAt &&
              item.unelgee2Scores &&
              item.unelgee2Scores.find(
                (score) =>
                  new Date(score.approvedAt.toDate()).getTime() >
                  new Date(item.finalGradeSavedToEsisAt.toDate()).getTime()
              )
            "
          >
            <small class="red--text">
              <v-icon color="red">mdi-alert</v-icon> Анхны хадгаласнаас хойш дүн
              засагдсан байна, ЭСИС-т xадгалагдаагүй байх магадлалтай. Менежер
              даxин xадгалаx товчоо дарж ЭСИС-тай тулгаарай.
            </small>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="showUnelgeeDialog" max-width="80%" v-if="selectedLesson">
      <v-card class="py-4 scoreTables">
        <div class="card-header-padding pb-0 pt-0">
          <v-row class="py-2">
            <v-col>
              <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-4">
                Үнэлгээ 2 - {{ userData.school.currentYear }},
                <span class="blue--text ml-4" v-if="selectedLesson">
                  {{ selectedLesson.courseInfo.COURSE_NAME }}/
                  <span
                    v-if="
                      selectedLesson.classGroups &&
                      selectedLesson.classGroups.length > 0
                    "
                  >
                    {{ selectedLesson.classGroups[0].classGroupFullName }}
                  </span>
                </span>
              </h5>
            </v-col>
            <!-- <v-col class="justify-self-end" cols="auto">
              <v-switch
                disabled
                class="py-0 my-0"
                color="red"
                v-model="simpleCalculationModel"
                :label="
                  simpleCalculationModel
                    ? 'Энгийн'
                    : 'Ахисан (' + simpleCalculationModel + ')'
                "
              ></v-switch>
            </v-col> -->
          </v-row>
          <v-divider></v-divider>
        </div>
        <ScoreInputUnelgee22Dialog
          class="pt-0"
          v-if="
            selectedLesson &&
            selectedLesson.students &&
            selectedLesson.unelgee2Scores &&
            selectedLesson.unelgee1Scores
          "
          v-bind="$attrs"
          :simpleCalculationModel="true"
          :selectedHalfYear="selectedHalfYear"
          :lesson="selectedLesson"
        >
        </ScoreInputUnelgee22Dialog>

        <div v-else class="text-center mt-16">
          <h3>Хичээл сурагчгүй байна. Сурагчаа бүргэнэ үү!</h3>
        </div>
        <v-card-text
          class="mx-10 px-4 text-center"
          style="width: 80%; overflow: hidden"
          v-if="savingUnelgee"
        >
          <p style="display: block: !important;" class="red--text">
            Дүн хадгалж байна. Түр хүлээнэ үү!
          </p>
          <v-progress-linear
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="mx-6 py-10 mt-14" v-else>
          <v-spacer></v-spacer>
          <v-btn color="grey" class="white--text" @click="_closeUnelgeeDialog">
            Цуцлах
          </v-btn>
          <v-btn
            @click="_sendUnelgeeToManager"
            class="bg-gradient-danger white--text ml-2 text-capitalize"
          >
            Xадгалаад илгээх
          </v-btn>
          <v-btn
            @click="_saveUnelgee2OfStudents"
            class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
          >
            Хадгалах
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- <v-card>
        <v-card-title>
          Dialog Title
          <v-spacer></v-spacer>
          <v-btn icon @click="showUnelgeeDialog = !showUnelgeeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <table style="height: 200px">
            <tbody>
              <tr
                v-for="(student, sIndex) in selectedLesson.students"
                :key="student.id + sIndex"
              >
                <td>{{ student.FIRST_NAME }}</td>
                <td>{{ student.LAST_NAME }}</td>
                <td>{{ student.DATE_OF_BIRTH }}</td>
                <td>---</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showUnelgeeDialog = !showUnelgeeDialog"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card> -->
    </v-dialog>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ScoreInputUnelgee22Dialog from "./ScoreInputUnelgee22Dialog";
const fb = require("@/firebaseConfig.js");
import axios from "axios";
import moment from "moment";

export default {
  components: {
    ScoreInputUnelgee22Dialog,
  },
  data: () => ({
    halfYearDate: null,
    halfYear2Date: null,
    savingUnelgee: false,
    simpleCalculationModel: true,
    loadingInverval: null,
    selectedLesson: null,
    showUnelgeeDialog: false,
    students: null,
    preText: null,
    selectedHalfYear: null,
    lessons: null,
    xSelectedTab: null,
    headerNames: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Xичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
      },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "esisLessonType",
      },
      {
        text: "Бүлэг",
        align: "start",
        value: "classGroups",
        sortable: true,
      },
      {
        text: "Суралцагч",
        align: "start",
        value: "numberOfStudents",
        sortable: true,
        width: "2%",
      },
      // {
      //   text: "Дундаж дүн",
      //   align: "start",
      //   value: "scoreAverage",
      //   sortable: true,
      //   width: "2%",
      // },
      {
        text: "Гүйцэтгэл",
        align: "start",
        value: "approvedPercentage",
        sortable: true,
        width: "2%",
      },
      {
        text: "Батлагдсан",
        align: "center",
        value: "approvedNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Хянагдаж байгаа",
        align: "center",
        value: "inProgressNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Цуцлагдсан",
        align: "center",
        value: "rejectedNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Бүртгэсэн",
        align: "center",
        value: "sentNumber",
        sortable: true,
        width: "2%",
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
      {
        style: "background-color: #e8f5e9",
        class: "green--text",
        text: "Дүнгүүдийг жилийн эцсийн дүнгээр",
        align: "start",
        value: "finalGradeSavedToEsis",
      },
      {
        style: "background-color: #e8f5e9",
        class: "green--text",
        text: "",
        align: "start",
        value: "finalGradeSavedToEsisAt",
      },
    ],
    loading: false,
    studentLoading: false,
    triggerUnelgeeDialog: false,
    totalLessonCounter: null,
  }),
  props: {},
  watch: {
    showUnelgeeDialog(val) {
      if (val == false) {
        this.triggerUnelgeeDialog = false;
        this.lessons
          .filter((l) => l.studentLoading)
          .forEach((lesson) => {
            lesson.studentLoading = false;
          });
      }
    },
    triggerUnelgeeDialog(val) {
      if (val) {
        var lesson = this.lessons.find(
          (lessn) => lessn.id == this.selectedLesson.id
        );
        if (lesson) {
          if (lesson.students && lesson.unelgee2Scores) {
            this.selectedLesson = Object.assign({}, lesson);
            this._doUnelgee();
          } else {
            var interval = setInterval(async () => {
              console.log(lesson.students, lesson.unelgee2Scores);
              if (lesson.students && !lesson.unelgee2Scores) {
                lesson.unelgee2Scores = await this.getUnelgeeData(lesson);
              }
              if (lesson.students && lesson.unelgee2Scores) {
                this.selectedLesson = Object.assign({}, lesson);
                this._doUnelgee();
                clearInterval(interval);
              }
            }, 500);
          }
        } else {
          this.triggerUnelgeeDialog = false;
        }
      }
    },
    selectedHalfYear(val) {
      val.name =
        this.preText + " xичээлийн жил, " + val.yearId + "-р xагас жил";
      console.log(val);
      this.setupp(true);
    },
    lessons(val) {
      if (val) {
        this.loading = true;
      }
    },
    loading(val) {
      if (val) {
        if (this.loadingInverval) {
          clearInterval(this.loadingInverval);
        }
        this.loadingInverval = setInterval(() => {
          var allDone = true;
          var allSaved = true;
          var stillLoading = false;
          const lsnLength = this.lessons.length;
          this.lessons.forEach((lesson) => {
            // if (!lesson["analyticsSaved-" + this.selectedHalfYear.yearId]) {
            if (
              !lesson[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  this.userData.id
              ]
            ) {
              allSaved = false;
            }
            if (
              !lesson.students &&
              !(
                lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.userData.id
                ] || lesson.unelgee2Scores
              )
            ) {
              allDone = false;
            }

            if (
              this.totalLessonCounter > 0 &&
              this.totalLessonCounter != this.lessons.length
            ) {
              stillLoading = true;
            }
            // }
          });
          if (!stillLoading) {
            if (allSaved) {
              this.loading = false;
              clearInterval(this.loadingInverval);
            } else {
              setTimeout(() => {
                if (allDone && lsnLength == this.lessons.length) {
                  this.loading = false;
                  clearInterval(this.loadingInverval);
                } else {
                  setTimeout(() => {
                    this.loading = false;
                    clearInterval(this.loadingInverval);
                  }, 10000);
                }
              }, 1500);
            }
          }
        }, 500);
      }
    },
  },
  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
    teacherId() {
      return this.userData.id;
    },
    filteredLessons() {
      var list = [];
      if (this.lessons) list = [...this.lessons];
      list.sort(
        (a, b) => a.courseInfo.PROGRAM_STAGE_ID - b.courseInfo.PROGRAM_STAGE_ID
      );
      list.forEach((item, idx) => {
        item.index = idx + 1;
      });
      return list;
    },
  },

  methods: {
    _getConfirmedProcent(lesson) {
      if (
        lesson.students ||
        (lesson[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            this.userData.id
        ] &&
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ].studentsLength)
      ) {
        var xxx = lesson.students
          ? lesson.students.length
          : lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.userData.id
            ].studentsLength;

        var yyy = this.getAnalystics(lesson, 1);
        return !isNaN(yyy / xxx)
          ? ((yyy / xxx) * 100 == 100 || (yyy / xxx) * 100 == 0
              ? (yyy / xxx) * 100
              : yyy / xxx > 1
              ? 100
              : ((yyy / xxx) * 100).toFixed(2)) + "%"
          : "-";
      } else {
        return "-";
      }
    },
    // getSize() {
    //   var counter = 0
    //   for(var classId in this.allClassGroups) {
    //     counter++
    //   }
    //   return counter
    // },
    _print2(lesson) {
      console.log(lesson);
    },
    async _doUnelgee() {
      console.log(this.selectedLesson, this.selectedLesson.students);
      this.selectedLesson.students.sort((a, b) =>
        a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
      );

      var query = this.userData.school.ref
        .collection("courses-" + this.userData.school.currentYear)
        .doc(this.selectedLesson.courseInfo.COURSE_ID.toString())
        .collection("unelgee1-" + this.userData.school.currentYear + "-scores");

      if (this.selectedLesson.esisLessonType.esisLessonTypeId > 1) {
        var availableLessonGroups = this.selectedLesson.lessonGroups.map(
          (lg) => lg.groupIndex
        );
        availableLessonGroups = availableLessonGroups.filter(function (
          element
        ) {
          return element !== undefined;
        });
        if (availableLessonGroups && availableLessonGroups.length > 0) {
          query = query.where("lessonGroupIndex", "in", availableLessonGroups);
        }
        this.selectedLesson.students.sort(
          (a, b) => a.lessonGroup.groupIndex - b.lessonGroup.groupIndex
        );
      }
      await query.onSnapshot((querySnapshot) => {
        this.selectedLesson.unelgee1Scores = [];
        var counter = 0;
        if (querySnapshot.size == 0) {
          this.showUnelgeeDialog = !this.showUnelgeeDialog;
        }
        querySnapshot.forEach(async (doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          await item.examRef.get().then((doc) => {
            if (doc.exists && doc.data().deleted != true) {
              item.examDate = new Date(doc.data().examDate);
              item.deleted = false;
            } else {
              item.deleted = true;
            }
          });
          if (item.deleted == false) {
            this.selectedLesson.unelgee1Scores.push(item);
          }
          counter++;
          if (counter == querySnapshot.size) {
            this.showUnelgeeDialog = true;
          }
        });
      });
    },
    async _findGroupStudents(lesson) {
      var allStudents = [];
      lesson.lessonGroups = [];
      var lessonGroups = await lesson.ref
        .collection("lesson-groups")
        .where("teacher.teacherRef", "==", this.userData.ref)
        .orderBy("groupIndex", "asc")
        .get()
        .then((docs) => {
          let list = [];
          docs.forEach((doc) => {
            let lg = doc.data();
            lg.ref = doc.ref;
            lg.id = doc.id;
            list.push(lg);
          });
          return list;
        });
      for (var lg of lessonGroups) {
        lesson.lessonGroups.push(lg);
        lg.students = [];
        if (lg.allStudentsSelected) {
          lesson.classGroupStudents.forEach((student) => {
            let stud = Object.assign({}, student);
            stud.lessonGroup = lg;
            stud.classGroup = lesson.classGroups[0];
            allStudents.push(stud);
            lg.students.push(stud);
          });
        } else {
          await lg.ref
            .collection("students")
            .get()
            .then((docs) => {
              docs.forEach(async (doc) => {
                let student = doc.data();
                student.ref = doc.ref;
                student.id = doc.id;
                student.lessonGroup = lg;

                student.classGroup = lesson.classGroups[0];
                if (
                  lesson.classGroupStudents.find(
                    (stud) => stud.PERSON_ID == student.PERSON_ID
                  )
                ) {
                  allStudents.push(student);
                  lg.students.push(student);
                }
              });
            });
        }
      }
      this.$forceUpdate();
      return allStudents;
    },
    forceSave() {
      this.lessons.forEach((lesson) => {
        var tmp = {};
        tmp["analyticsSaved-" + this.selectedHalfYear.yearId] = false;

        lesson.ref.update(tmp);
      });
    },
    getAnalystics(lesson, mode) {
      let approvedNumber = 0;
      let rejectedNumber = 0;
      let inProgressNumber = 0;
      let sentNumber = 0;
      // if (lesson["analyticsSaved-" + this.selectedHalfYear.yearId]) {
      //   approvedNumber =
      //     lesson[
      //       "year" +
      //         this.selectedHalfYear.yearId +
      //         "UnelgeeScores-" +
      //         this.userData.id
      //     ].approvedNumber;
      //   rejectedNumber =
      //     lesson[
      //       "year" +
      //         this.selectedHalfYear.yearId +
      //         "UnelgeeScores-" +
      //         this.userData.id
      //     ].rejectedNumber;
      //   inProgressNumber =
      //     lesson[
      //       "year" +
      //         this.selectedHalfYear.yearId +
      //         "UnelgeeScores-" +
      //         this.userData.id
      //     ].inProgressNumber;
      //   sentNumber =
      //     lesson[
      //       "year" +
      //         this.selectedHalfYear.yearId +
      //         "UnelgeeScores-" +
      //         this.userData.id
      //     ].sentNumber;
      // } else {
      if (
        lesson.unelgee2Scores &&
        lesson.unelgee2Scores.length > 0 &&
        lesson.students &&
        lesson.students.length > 0
      ) {
        lesson.unelgee2Scores.forEach((unelgee2) => {
          let foundStud;
          foundStud = lesson.students.find(
            (student) => unelgee2.PERSON_ID == student.PERSON_ID
          );
          if (foundStud) {
            if (unelgee2.approved == true) {
              approvedNumber++;
            } else if (unelgee2.approved == false) {
              rejectedNumber++;
            } else if (unelgee2.sentToManager == true) {
              inProgressNumber++;
            } else if (
              unelgee2.sentToManager != true &&
              unelgee2.score != null &&
              unelgee2.score != undefined &&
              typeof unelgee2.score == "number"
            ) {
              sentNumber++;
            }
          }
        });
      } else if (
        lesson[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            this.userData.id
        ]
      ) {
        lesson[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            this.userData.id
        ];
        approvedNumber =
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ].approvedNumber;

        inProgressNumber =
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ].inProgressNumber;
        rejectedNumber =
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ].rejectedNumber;
        sentNumber =
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ].sentNumber;
      }
      // }
      if (mode == 1) {
        return approvedNumber;
      } else if (mode == 2) {
        return inProgressNumber;
      } else if (mode == 3) {
        return rejectedNumber;
      } else if (mode == 4) {
        return sentNumber;
      }
    },
    async getUnelgeeData(lesson) {
      var list = [];
      var query = this.userData.school.ref
        .collection("unelgee2-" + this.userData.school.currentYear + "-scores")
        .doc(
          this.selectedHalfYear.yearId > 1
            ? lesson.id + "-" + this.selectedHalfYear.yearId
            : lesson.id
        )
        .collection("scores");
      // console.log(
      //   lesson.esisLessonType.esisLessonTypeId,
      //   lesson.courseInfo.COURSE_NAME
      // );
      if (lesson.esisLessonType.esisLessonTypeId > 1) {
        var availableLessonGroups = lesson.lessonGroups
          .filter((lg) => lg.groupIndex && lg.teacher)
          .map((lg) => lg.groupIndex);

        if (availableLessonGroups && availableLessonGroups.length > 0)
          query = query.where("lessonGroupIndex", "in", availableLessonGroups);
      }
      await query.get().then((docs) => {
        console.log(docs.size);
        docs.forEach((doc) => {
          let studentScore = doc.data();
          studentScore.id = doc.id;
          studentScore.ref = doc.ref;

          if (
            lesson.classGroupStudents.find(
              (student) => student.PERSON_ID == studentScore.PERSON_ID
            )
          ) {
            if (lesson.esisLessonType.esisLessonTypeId > 1) {
              if (
                lesson.lessonGroups.find(
                  (lg) => lg.id == studentScore.lessonGroupId
                ) &&
                lesson.lessonGroups.find(
                  (lg) => lg.id == studentScore.lessonGroupId
                ).students &&
                lesson.lessonGroups
                  .find((lg) => lg.id == studentScore.lessonGroupId)
                  .students.find(
                    (stud) => stud.PERSON_ID == studentScore.PERSON_ID
                  )
              ) {
                list.push(studentScore);
              }
            } else {
              if (!studentScore.lessonGroupId) list.push(studentScore);
            }
          }
        });
      });
      this.$forceUpdate();
      return list;
    },
    isAllowedLesson(lesson) {
      if (lesson && lesson.classGroupRefs) {
        for (var ll of lesson.classGroupRefs) {
          if (ll.path.includes("/departments/")) {
            lesson.isNotAllowed = true;
            break;
          }
        }
      }
    },

    _closeUnelgeeDialog() {
      this.showUnelgeeDialog = !this.showUnelgeeDialog;
      this.selectedLesson = null;
    },

    async _sendUnelgeeToManager() {
      if (this.checkIfAllScoresExists() == true) {
        this.$swal({
          title: "Үнэлгээ 2-ийн дүнг Менежерт илгээх үү?",
          text: "Менежерийн баталгаажуулсан, цуцалсан мэдээлэл хяналтын самбарт харагдана!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            var numberOfScoredStudents = 0;
            if (this.selectedHalfYear.yearId > 1) {
              var unelgeeLessonPath =
                this.userData.school.ref.path +
                "/unelgee2-" +
                this.userData.school.currentYear +
                "-scores/" +
                this.selectedLesson.id +
                "-" +
                this.selectedHalfYear.yearId;
            } else {
              unelgeeLessonPath =
                this.userData.school.ref.path +
                "/unelgee2-" +
                this.userData.school.currentYear +
                "-scores/" +
                this.selectedLesson.id;
            }
            var unelgeePath = unelgeeLessonPath + "/scores";

            var batch = fb.db.batch();
            //var batchStudent = fb.db.batch()
            this.savingUnelgee = true;

            var approvedNumber = 0;
            var inProgressNumber = 0;
            var rejectedNumber = 0;
            var sentNumber = 0;
            var scoreAverage = 0;
            console.log(this.selectedLesson.students, this.selectedLesson);
            var worked = false;
            for (var student of this.selectedLesson.students) {
              if (
                student &&
                student.score != null &&
                student.score != undefined &&
                typeof student.score == "number"
              ) {
                var scoreUnelgee = Object.assign({}, student);
                if (student.lessonGroup) {
                  if (
                    unelgeeLessonPath.includes(
                      String(student.STUDENT_GROUP_ID)
                    ) == false
                  ) {
                    if (this.selectedHalfYear.yearId > 1) {
                      unelgeeLessonPath =
                        this.userData.school.ref.path +
                        "/unelgee2-" +
                        this.userData.school.currentYear +
                        "-scores/" +
                        student.STUDENT_GROUP_ID +
                        "-" +
                        this.selectedLesson.courseInfo.COURSE_ID +
                        "-" +
                        this.selectedHalfYear.yearId;
                    } else {
                      unelgeeLessonPath =
                        this.userData.school.ref.path +
                        "/unelgee2-" +
                        this.userData.school.currentYear +
                        "-scores/" +
                        student.STUDENT_GROUP_ID +
                        "-" +
                        this.selectedLesson.courseInfo.COURSE_ID;
                    }

                    unelgeePath = unelgeeLessonPath + "/scores";
                  }
                  scoreUnelgee["lessonGroupId"] = student.lessonGroup.id;
                  scoreUnelgee["lessonGroupIndex"] =
                    student.lessonGroup.groupIndex;
                  scoreUnelgee["lessonGroupRef"] = student.lessonGroup.ref;
                }
                scoreUnelgee["lessonGroup"] = null;
                scoreUnelgee["schoolId"] = this.userData.school.id;
                scoreUnelgee["schoolName"] = this.userData.school.name;
                scoreUnelgee["createdByRef"] = this.userData.ref;
                scoreUnelgee["createdByName"] = this.userData["DISPLAY_NAME"]
                  ? this.userData["DISPLAY_NAME"]
                  : null;
                scoreUnelgee["lesson"] = null;
                scoreUnelgee["lessonId"] = this.selectedLesson.id;
                scoreUnelgee["lessonRef"] = this.selectedLesson.ref;
                scoreUnelgee["courseInfo"] = this.minimizedCourseInfo(
                  this.selectedLesson.courseInfo
                ); //minimize
                if (this.selectedHalfYear.yearId > 1) {
                  scoreUnelgee["semester"] = 4;
                  scoreUnelgee["year1"] = 2;
                } else {
                  scoreUnelgee["semester"] = 2;
                  scoreUnelgee["year1"] = 1;
                }
                scoreUnelgee["year"] = this.userData.school.currentYear;

                scoreUnelgee["createdAt"] = new Date();
                scoreUnelgee["updatedAt"] = new Date();

                // scoreUnelgee["score"] = scoreUnelgee["score"]
                // scoreUnelgee["score"] = scoreUnelgee["score"]
                // scoreUnelgee["description"] = scoreUnelgee["unelgee2"]["description"]

                scoreUnelgee["unelgee2"] = null;

                // if (unelgeePath2.score) console.log(unelgeePath2);
                if (scoreUnelgee.scoreFinal) console.log(scoreUnelgee);
                if (student.unelgee1Scores)
                  scoreUnelgee["unelgee1Scores"] = this.minimizedUnelgee1(
                    student.unelgee1Scores
                  );
                if (student.averageScore) {
                  scoreUnelgee["averageScore"] = student.averageScore;
                }
                if (student.highestScore) {
                  scoreUnelgee["highestScore"] = student.highestScore;
                  scoreUnelgee["highestScoreExam"] = student.highestScoreExam;
                }
                if (student.latestScore) {
                  scoreUnelgee["latestScore"] = student.latestScore;
                  scoreUnelgee["latestScoreExam"] = student.latestScoreExam;
                }
                if (student.scoreBasedOnState) {
                  scoreUnelgee["scoreBasedOnState"] = student.scoreBasedOnState;
                }
                if (student.scoreBasedOnExam) {
                  scoreUnelgee["scoreBasedOnExam"] = student.scoreBasedOnExam;
                }

                scoreUnelgee["gradedBy"] = this.minimizedGradedBy(
                  this.userData
                );
                scoreUnelgee["sentToManager"] = true;

                // var unelgeeStudentPath = student.ref.path+"/"+"student-courses-"+this.userData.school.currentYear
                // + "/"+this.selectedLesson.courseInfo.COURSE_ID
                // +"/student-unelgee1-"+this.userData.school.currentYear
                if (
                  student.changedScore ||
                  (student.scoreState != true && student.sentToManager != true)
                ) {
                  if (
                    scoreUnelgee.score != null &&
                    scoreUnelgee.score != undefined &&
                    typeof student.score == "number"
                  ) {
                    scoreUnelgee["sentToManager"] = true;
                    scoreUnelgee["sentToManagerAt"] = new Date();
                    if (
                      student.modifiedScore &&
                      student.scoreState != null &&
                      student.scoreState != -1
                    ) {
                      batch.update(
                        fb.db
                          .collection(unelgeePath)
                          .doc(
                            this.selectedLesson.esisLessonType
                              .esisLessonTypeId > 1
                              ? student.id + "-" + student.lessonGroup.id
                              : student.id
                          ),
                        {
                          approved: fb.firestore.FieldValue.delete(),
                          approvedAt: fb.firestore.FieldValue.delete(),
                          approvedByRef: fb.firestore.FieldValue.delete(),
                        }
                      );
                    }
                    // fb.db
                    //   .collection(unelgeePath2)
                    //   .doc(student.id)
                    //   .get()
                    //   .then((doc) => {
                    //     if (doc.exists && doc.data().score != scoreUnelgee.score) {
                    //       console.log(doc.data().score, scoreUnelgee.score);
                    //       doc.ref.update({
                    //         approved: fb.firestore.FieldValue.delete(),
                    //         approvedAt: fb.firestore.FieldValue.delete(),
                    //         approvedByRef: fb.firestore.FieldValue.delete(),
                    //       });
                    //     }
                    //   });

                    numberOfScoredStudents++;
                    // var docName1=this.currentSelectedExam.id+"-"+student.id
                    // if(this.selectedLesson.isGroupBased){
                    //   docName1 = docName1 + "-"+student.lessonGroup.id
                    // }

                    batch.set(
                      fb.db
                        .collection(unelgeePath)
                        .doc(
                          this.selectedLesson.esisLessonType.esisLessonTypeId >
                            1
                            ? student.id + "-" + student.lessonGroup.id
                            : student.id
                        ),
                      scoreUnelgee,
                      { merge: true }
                    );
                    // batch.set(fb.db.collection(unelgeeStudentPath).doc(docName2), scoreUnelgee,{merge:true})
                  }
                }
                if (scoreUnelgee.approved == true) {
                  if (typeof scoreUnelgee.score == "number")
                    scoreAverage = scoreAverage + scoreUnelgee.score;
                  approvedNumber++;
                  worked = true;
                } else if (scoreUnelgee.approved == false) {
                  rejectedNumber++;
                  worked = true;
                } else if (scoreUnelgee.sentToManager == true) {
                  inProgressNumber++;
                  worked = true;
                } else if (
                  scoreUnelgee.sentToManager != true &&
                  scoreUnelgee.score != null &&
                  scoreUnelgee.score != undefined &&
                  typeof scoreUnelgee.score == "number"
                ) {
                  worked = true;
                  sentNumber++;
                }
              }
            }

            var tmp = {};
            tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] = {
              approvedNumber: 0,
              rejectedNumber: 0,
              inProgressNumber: 0,
              sentNumber: 0,
            };

            tmp[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.userData.id
            ] = {
              approvedNumber: approvedNumber,
              rejectedNumber: rejectedNumber,
              inProgressNumber: inProgressNumber,
              sentNumber: sentNumber,
              scoreAverage: (scoreAverage / approvedNumber).toFixed(2),
            };
            tmp["sentToManager"] = worked;
            this.selectedLesson.byTeachers.forEach((teacher) => {
              if (
                this.selectedLesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    teacher.teacherId
                ]
              ) {
                if (teacher.teacherId == this.userData.id) {
                  tmp[
                    "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                  ].approvedNumber =
                    tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                      .approvedNumber +
                    tmp[
                      "year" +
                        this.selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacher.teacherId
                    ].approvedNumber;
                  tmp[
                    "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                  ].rejectedNumber =
                    tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                      .rejectedNumber +
                    tmp[
                      "year" +
                        this.selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacher.teacherId
                    ].rejectedNumber;
                  tmp[
                    "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                  ].inProgressNumber =
                    tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                      .inProgressNumber +
                    tmp[
                      "year" +
                        this.selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacher.teacherId
                    ].inProgressNumber;
                  tmp[
                    "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                  ].sentNumber =
                    tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                      .sentNumber +
                    tmp[
                      "year" +
                        this.selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacher.teacherId
                    ].sentNumber;
                } else {
                  tmp[
                    "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                  ].approvedNumber =
                    tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                      .approvedNumber +
                    this.selectedLesson[
                      "year" +
                        this.selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacher.teacherId
                    ].approvedNumber;
                  tmp[
                    "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                  ].rejectedNumber =
                    tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                      .rejectedNumber +
                    this.selectedLesson[
                      "year" +
                        this.selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacher.teacherId
                    ].rejectedNumber;
                  tmp[
                    "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                  ].inProgressNumber =
                    tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                      .inProgressNumber +
                    this.selectedLesson[
                      "year" +
                        this.selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacher.teacherId
                    ].inProgressNumber;
                  tmp[
                    "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                  ].sentNumber =
                    tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                      .sentNumber +
                    this.selectedLesson[
                      "year" +
                        this.selectedHalfYear.yearId +
                        "UnelgeeScores-" +
                        teacher.teacherId
                    ].sentNumber;
                }
              }
            });
            console.log(tmp);
            //currentSelectedLesson
            //24115 end ammjittai bolvol line 213 g oorchilvol yamar ve?
            batch.set(this.selectedLesson.ref, tmp, { merge: true });
            batch.set(
              fb.db.doc(unelgeeLessonPath),
              {
                numberOfScoredStudents: numberOfScoredStudents,
                numberOfStudents: this.selectedLesson.students.length,
                calculationModel: this.simpleCalculationModel,
                createdAt: new Date(),
              },
              { merge: true }
            );
            batch.commit().then(() => {
              console.log(tmp);
              this.handleTeacherAndClassGroupAnalytics();
              var lesson = this.lessons.find(
                (lesson) => lesson.id == this.selectedLesson.id
              );
              lesson[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  this.userData.id
              ] =
                tmp[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.userData.id
                ];
              this.$forceUpdate();
              this.savingUnelgee = false;
              for (var student of this.selectedLesson.students) {
                student.score = null;
                student.scoreFinal = null;
              }
              this._closeUnelgeeDialog();
              this.$swal.fire("Амжилттай илгээгдлээ!");
              // this.getLessonScoreAnalytics(this.selectedLesson);
            });

            console.log(numberOfScoredStudents);
            console.log(
              this.selectedLesson.courseInfo.COURSE_ID,
              this.selectedLesson.courseInfo
            );
          }
        });
      } else {
        this.$swal({
          title: "Бүх сурагч дээр дүн орсон тохиолдолд илгээх боломжтой!",
          text: "Бүх сурагчийн дүнгийг оруулсаны дараа дахин оролдоно уу.",
          type: "warning",
          showCancelButton: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          confirmButtonText: "Ок",
          reverseButtons: true,
        });
      }
    },
    minimizedGradedBy(userData) {
      var tmp = {
        DISPLAY_NAME: userData.DISPLAY_NAME,
        EMAIL: userData.EMAIL,
        USERNAME: userData.USERNAME,
        FIRST_NAME: userData.FIRST_NAME,
        LAST_NAME: userData.LAST_NAME,
        ref: userData.ref,
        PERSON_ID: userData.PERSON_ID,
      };
      return tmp;
    },
    minimizedCourseInfo(courseInfo) {
      var tmp = {
        COURSE_ID: courseInfo.COURSE_ID,
        COURSE_NAME: courseInfo.COURSE_NAME,
        ENROLLMENT_CATEGORY: courseInfo.ENROLLMENT_CATEGORY,
        GRADING_SCHEME_ID: courseInfo.GRADING_SCHEME_ID,
        PROGRAM_OF_STUDY_ID: courseInfo.PROGRAM_OF_STUDY_ID,
        PROGRAM_ELEMENT_ID: courseInfo.PROGRAM_ELEMENT_ID,
        PROGRAM_STAGE_ID: courseInfo.PROGRAM_STAGE_ID,
        SUBJECT_AREA_ID: courseInfo.SUBJECT_AREA_ID,
      };
      return tmp;
    },
    getScoreLevel(item, mode) {
      let gradePoints;
      let gradeStr;
      let tmpScore = item.score;
      if (tmpScore >= 90) {
        gradePoints = 8;
        gradeStr = "VIII";
      } else if (tmpScore >= 80) {
        gradePoints = 7;
        gradeStr = "VII";
      } else if (tmpScore >= 70) {
        gradePoints = 6;
        gradeStr = "VI";
      } else if (tmpScore >= 60) {
        gradePoints = 5;
        gradeStr = "V";
      } else if (tmpScore >= 50) {
        gradePoints = 4;
        gradeStr = "IV";
      } else if (tmpScore >= 40) {
        gradePoints = 3;
        gradeStr = "III";
      } else if (tmpScore >= 30) {
        gradePoints = 2;
        gradeStr = "II";
      } else if (tmpScore >= 0 && typeof tmpScore == "number") {
        gradePoints = 1;
        gradeStr = "I";
      } else if (
        tmpScore == null ||
        tmpScore == undefined ||
        typeof tmpScore != "number"
      ) {
        gradePoints = null;
        gradeStr = null;
      }

      if (
        item.score > 0 &&
        (this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 14)
      ) {
        item.scoreLevelString = "Тооцов";
        gradePoints = 8;
      } else if (
        item.score == 0 &&
        (this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
          this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 14)
      ) {
        item.scoreLevelString = "Тооцоогүй";
        gradePoints = 8;
      }

      if (mode) {
        return gradeStr;
      } else {
        return gradePoints;
      }
    },
    minimizedUnelgee1(unelgee1Scores) {
      var tmp = [];
      unelgee1Scores.forEach((unelgee1) => {
        var tmp2 = {
          PERSON_ID: unelgee1.PERSON_ID,
          courseInfo: this.minimizedCourseInfo(unelgee1.courseInfo),
          lessonGroupId: unelgee1.lessonGroupId ? unelgee1.lessonGroupId : null,
          lessonGroupIndex: unelgee1.lessonGroupIndex
            ? unelgee1.lessonGroupIndex
            : null,
          score: unelgee1.score,
          scoreLevelNumber: unelgee1.scoreLevelNumber
            ? unelgee1.scoreLevelNumber
            : this.getScoreLevel(unelgee1, false),
          scoreLevelString: unelgee1.scoreLevelString
            ? unelgee1.scoreLevelString
            : this.getScoreLevel(unelgee1, true),
          year1: unelgee1.year1,
          year: unelgee1.year,
          deleted: unelgee1.deleted,
          examId: unelgee1.examId,
          examRef: unelgee1.examRef,
          examDate: unelgee1.examDate,
          lessonRef: unelgee1.lessonRef,
          lessonId: unelgee1.lessonId,
          createdByRef: unelgee1.createdByRef,
          createdByName: unelgee1.createdByName,
          createdAt: unelgee1.createdAt,
        };
        tmp.push(tmp2);
      });
      return tmp;
    },
    _saveUnelgee2OfStudents() {
      var numberOfScoredStudents = 0;
      if (this.selectedHalfYear.yearId > 1) {
        var unelgeeLessonPath =
          this.userData.school.ref.path +
          "/unelgee2-" +
          this.userData.school.currentYear +
          "-scores/" +
          this.selectedLesson.id +
          "-" +
          this.selectedHalfYear.yearId;
      } else {
        unelgeeLessonPath =
          this.userData.school.ref.path +
          "/unelgee2-" +
          this.userData.school.currentYear +
          "-scores/" +
          this.selectedLesson.id;
      }
      var unelgeePath = unelgeeLessonPath + "/scores";

      // var unelgeePath1 =
      //   this.userData.school.ref.path +
      //   "/unelgee2-" +
      //   this.userData.school.currentYear +
      //   "-scores/" +
      //   this.selectedLesson.id;
      // var unelgeePath2 = unelgeePath1 + "/scores";

      var batch = fb.db.batch();
      //var batchStudent = fb.db.batch()
      this.savingUnelgee = true;

      var approvedNumber = 0;
      var inProgressNumber = 0;
      var rejectedNumber = 0;
      var sentNumber = 0;
      var scoreAverage = 0;

      var worked = false;
      for (var student of this.selectedLesson.students) {
        if (
          student &&
          student.score != null &&
          student.score != undefined &&
          typeof student.score == "number"
        ) {
          var scoreUnelgee = Object.assign({}, student);
          if (student.lessonGroup) {
            if (
              unelgeeLessonPath.includes(String(student.STUDENT_GROUP_ID)) ==
              false
            ) {
              if (this.selectedHalfYear.yearId > 1) {
                unelgeeLessonPath =
                  this.userData.school.ref.path +
                  "/unelgee2-" +
                  this.userData.school.currentYear +
                  "-scores/" +
                  student.STUDENT_GROUP_ID +
                  "-" +
                  this.selectedLesson.courseInfo.COURSE_ID +
                  "-" +
                  this.selectedHalfYear.yearId;
              } else {
                unelgeeLessonPath =
                  this.userData.school.ref.path +
                  "/unelgee2-" +
                  this.userData.school.currentYear +
                  "-scores/" +
                  student.STUDENT_GROUP_ID +
                  "-" +
                  this.selectedLesson.courseInfo.COURSE_ID;
              }

              unelgeePath = unelgeeLessonPath + "/scores";
            }
            scoreUnelgee["lessonGroupId"] = student.lessonGroup.id;
            scoreUnelgee["lessonGroupIndex"] = student.lessonGroup.groupIndex;
            scoreUnelgee["lessonGroupRef"] = student.lessonGroup.ref;
          }
          scoreUnelgee["lessonGroup"] = null;
          scoreUnelgee["schoolId"] = this.userData.school.id;
          scoreUnelgee["schoolName"] = this.userData.school.name;
          scoreUnelgee["createdByRef"] = this.userData.ref;
          scoreUnelgee["createdByName"] = this.userData["DISPLAY_NAME"]
            ? this.userData["DISPLAY_NAME"]
            : null;
          scoreUnelgee["lesson"] = null;
          scoreUnelgee["lessonId"] = this.selectedLesson.id;
          scoreUnelgee["lessonRef"] = this.selectedLesson.ref;
          scoreUnelgee["courseInfo"] = this.minimizedCourseInfo(
            this.selectedLesson.courseInfo
          ); //minimize
          if (this.selectedHalfYear.yearId > 1) {
            scoreUnelgee["semester"] = 4;
            scoreUnelgee["year1"] = 2;
          } else {
            scoreUnelgee["semester"] = 2;
            scoreUnelgee["year1"] = 1;
          }
          scoreUnelgee["year"] = this.userData.school.currentYear;

          scoreUnelgee["createdAt"] = new Date();
          scoreUnelgee["updatedAt"] = new Date();

          // scoreUnelgee["score"] = scoreUnelgee["score"]
          // scoreUnelgee["score"] = scoreUnelgee["score"]
          // scoreUnelgee["description"] = scoreUnelgee["unelgee2"]["description"]

          scoreUnelgee["unelgee2"] = null;

          // if (unelgeePath2.score) console.log(unelgeePath2);
          // if (scoreUnelgee.scoreFinal) console.log(scoreUnelgee);
          if (student.unelgee1Scores) {
            scoreUnelgee["unelgee1Scores"] = this.minimizedUnelgee1(
              student.unelgee1Scores
            );
          }
          if (student.averageScore) {
            scoreUnelgee["averageScore"] = student.averageScore;
          }
          if (student.highestScore) {
            scoreUnelgee["highestScore"] = student.highestScore;
            scoreUnelgee["highestScoreExam"] = student.highestScoreExam;
          }
          if (student.latestScore) {
            scoreUnelgee["latestScore"] = student.latestScore;
            scoreUnelgee["latestScoreExam"] = student.latestScoreExam;
          }
          if (student.scoreBasedOnState) {
            scoreUnelgee["scoreBasedOnState"] = student.scoreBasedOnState;
          }
          if (student.scoreBasedOnExam) {
            scoreUnelgee["scoreBasedOnExam"] = student.scoreBasedOnExam;
          }

          scoreUnelgee["gradedBy"] = this.minimizedGradedBy(this.userData); //minimize

          if (student.modifiedScore) {
            if (
              scoreUnelgee.score != null &&
              scoreUnelgee.score != undefined &&
              typeof scoreUnelgee.score == "number"
            ) {
              if (
                student.changedScore &&
                student.scoreState != null &&
                student.scoreState != -1
              ) {
                scoreUnelgee["sentToManager"] = false;
                batch.set(
                  fb.db
                    .collection(unelgeePath)
                    .doc(
                      this.selectedLesson.esisLessonType.esisLessonTypeId > 1
                        ? student.id + "-" + student.lessonGroup.id
                        : student.id
                    ),
                  {
                    approved: fb.firestore.FieldValue.delete(),
                    approvedAt: fb.firestore.FieldValue.delete(),
                    approvedByRef: fb.firestore.FieldValue.delete(),
                  },
                  { merge: true }
                );
              }

              numberOfScoredStudents++;
              // var docName1=this.currentSelectedExam.id+"-"+student.id
              // if(this.selectedLesson.isGroupBased){
              //   docName1 = docName1 + "-"+student.lessonGroup.id
              // }
              console.log(scoreUnelgee);
              batch.set(
                fb.db
                  .collection(unelgeePath)
                  .doc(
                    this.selectedLesson.esisLessonType.esisLessonTypeId > 1
                      ? student.id + "-" + student.lessonGroup.id
                      : student.id
                  ),

                scoreUnelgee,
                { merge: true }
              );
            }

            // batch.set(fb.db.collection(unelgeeStudentPath).doc(docName2), scoreUnelgee,{merge:true})
          }
          if (scoreUnelgee.approved == true) {
            approvedNumber++;
            if (typeof scoreUnelgee.score == "number")
              scoreAverage = scoreAverage + scoreUnelgee.score;
            worked = true;
          } else if (scoreUnelgee.approved == false) {
            rejectedNumber++;
            worked = true;
          } else if (scoreUnelgee.sentToManager == true) {
            inProgressNumber++;
            worked = true;
          } else if (
            scoreUnelgee.sentToManager != true &&
            scoreUnelgee.score != null &&
            scoreUnelgee.score != undefined &&
            typeof scoreUnelgee.score == "number"
          ) {
            worked = true;
            sentNumber++;
          }
        }
      }

      var tmp = {};
      tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] = {
        approvedNumber: 0,
        rejectedNumber: 0,
        inProgressNumber: 0,
        sentNumber: 0,
      };

      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.userData.id
      ] = {
        approvedNumber: approvedNumber,
        rejectedNumber: rejectedNumber,
        inProgressNumber: inProgressNumber,
        sentNumber: sentNumber,
        scoreAverage: (scoreAverage / approvedNumber).toFixed(2),
      };
      tmp["sentToManager"] = worked;

      this.selectedLesson.byTeachers.forEach((teacher) => {
        if (
          this.selectedLesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              teacher.teacherId
          ]
        ) {
          if (teacher.teacherId == this.userData.id) {
            tmp[
              "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
            ].approvedNumber =
              tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                .approvedNumber +
              tmp[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  teacher.teacherId
              ].approvedNumber;
            tmp[
              "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
            ].rejectedNumber =
              tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                .rejectedNumber +
              tmp[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  teacher.teacherId
              ].rejectedNumber;
            tmp[
              "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
            ].inProgressNumber =
              tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                .inProgressNumber +
              tmp[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  teacher.teacherId
              ].inProgressNumber;
            tmp[
              "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
            ].sentNumber =
              tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                .sentNumber +
              tmp[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  teacher.teacherId
              ].sentNumber;
          } else {
            tmp[
              "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
            ].approvedNumber =
              tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                .approvedNumber +
              this.selectedLesson[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  teacher.teacherId
              ].approvedNumber;
            tmp[
              "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
            ].rejectedNumber =
              tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                .rejectedNumber +
              this.selectedLesson[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  teacher.teacherId
              ].rejectedNumber;
            tmp[
              "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
            ].inProgressNumber =
              tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                .inProgressNumber +
              this.selectedLesson[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  teacher.teacherId
              ].inProgressNumber;
            tmp[
              "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
            ].sentNumber =
              tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
                .sentNumber +
              this.selectedLesson[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  teacher.teacherId
              ].sentNumber;
          }
        }
      });
      console.log(tmp);
      batch.set(this.selectedLesson.ref, tmp, { merge: true });
      batch.set(
        fb.db.doc(unelgeeLessonPath),
        {
          numberOfScoredStudents: numberOfScoredStudents,
          numberOfStudents: this.selectedLesson.students.length,
          calculationModel: this.simpleCalculationModel,
          createdAt: new Date(),
        },
        { merge: true }
      );
      batch.commit().then(() => {
        this.handleTeacherAndClassGroupAnalytics();
        this.savingUnelgee = false;
        for (var student of this.selectedLesson.students) {
          student.score = null;
          student.scoreFinal = null;
        }
        var lesson = this.lessons.find(
          (lesson) => lesson.id == this.selectedLesson.id
        );
        lesson[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            this.userData.id
        ] =
          tmp[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.userData.id
          ];
        this.$forceUpdate();
        this._closeUnelgeeDialog();
        this.$swal.fire("Амжилттай xадгалагдсан!");
        // this.getLessonScoreAnalytics(this.selectedLesson);
      });

      console.log(numberOfScoredStudents);
      console.log(
        this.selectedLesson.courseInfo.COURSE_ID,
        this.selectedLesson.courseInfo
      );
    },
    async handleTeacherAndClassGroupAnalytics() {
      const headers = {
        "Content-Type": "application/json",
      };
      var requestBody = {
        classGroupRef: this.selectedLesson.classGroupRefs[0].path,
        schoolRef: this.userData.school.ref.path,
        schoolYear: this.userData.school.currentYear,
        selectedHalfYear: this.selectedHalfYear,
        halfYearDate: this.halfYearDate,
        halfYear2Date: this.halfYear2Date,
      };
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/classGroupUnelgee2Analytics",
          requestBody,
          headers
        )
        .then((response) => {
          console.log(response);
        });
      var teacherAnalytics = {
        approvedNumber: 0,
        rejectedNumber: 0,
        inProgressNumber: 0,
        sentNumber: 0,
        scoreAverage: 0,
        // totalLessonStudentsNumber: 0,
        electiveLessonNumber: 0,
        mandatoryLessonNumber: 0,
      };
      for (var lesson of this.filteredLessons) {
        if (lesson.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE") {
          teacherAnalytics.electiveLessonNumber++;
        } else if (lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY") {
          teacherAnalytics.mandatoryLessonNumber++;
        }
        // teacherAnalytics.totalLessonStudentsNumber += lesson.students.length;
        await this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .doc(
            this.selectedHalfYear.yearId > 1
              ? lesson.id + "-" + this.selectedHalfYear.yearId
              : lesson.id
          )
          .collection("scores")
          .get()
          .then((docs) => {
            console.log(docs.size);
            lesson.unelgee2Scores = [];
            docs.forEach((doc) => {
              let studentScore = doc.data();
              studentScore.ref = doc.ref;
              studentScore.id = doc.id;
              if (lesson.esisLessonType.esisLessonTypeId > 1) {
                if (
                  lesson.lessonGroups.find(
                    (lg) => lg.id == studentScore.lessonGroupId
                  ) &&
                  lesson.lessonGroups.find(
                    (lg) => lg.id == studentScore.lessonGroupId
                  ).students &&
                  lesson.lessonGroups
                    .find((lg) => lg.id == studentScore.lessonGroupId)
                    .students.find(
                      (stud) => stud.PERSON_ID == studentScore.PERSON_ID
                    )
                ) {
                  lesson.unelgee2Scores.push(studentScore);
                }
              } else {
                lesson.unelgee2Scores.push(studentScore);
              }
            });
            for (var scoreUnelgee of lesson.unelgee2Scores) {
              if (scoreUnelgee.approved == true) {
                if (typeof scoreUnelgee.score == "number") {
                  if (scoreUnelgee.score <= 1) {
                    teacherAnalytics.scoreAverage =
                      teacherAnalytics.scoreAverage + 100;
                  } else {
                    teacherAnalytics.scoreAverage =
                      teacherAnalytics.scoreAverage + scoreUnelgee.score;
                  }
                }
                teacherAnalytics.approvedNumber++;
              } else if (scoreUnelgee.approved == false) {
                teacherAnalytics.rejectedNumber++;
              } else if (scoreUnelgee.sentToManager == true) {
                teacherAnalytics.inProgressNumber++;
              } else if (
                scoreUnelgee.sentToManager != true &&
                scoreUnelgee.score != null &&
                scoreUnelgee.score != undefined &&
                typeof scoreUnelgee.score == "number"
              ) {
                teacherAnalytics.sentNumber++;
              }
            }
          });
      }
      teacherAnalytics.scoreAverage = (
        teacherAnalytics.scoreAverage / teacherAnalytics.approvedNumber
      ).toFixed(2);
      let tmp = {};
      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.userData.school.currentYear
      ] = teacherAnalytics;
      this.userData.ref.update(tmp).then(() => {
        console.log("teacher done", this.userData.ref.path);
      });
    },
    checkIfAllScoresExists() {
      var status = true;
      for (var student of this.selectedLesson.students) {
        if (
          student.score == null ||
          student.score == undefined ||
          typeof student.score != "number"
        ) {
          status = false;
        }
      }
      return status;
    },
    async checkIfTypeChanged(lesson, mode) {
      if (mode == 2) {
        var docSize = await this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .doc(lesson.id)
          .collection("scores")
          .where("approved", "==", true)
          .get()
          .then((docs) => {
            return docs.docs.filter((doc) => !doc.data().lessonGroupId).length;
          });
        return docSize;
      } else if (mode == 1) {
        docSize = await this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-scores"
          )
          .doc(
            this.selectedHalfYear.yearId > 1
              ? lesson.id + "-" + this.selectedHalfYear.yearId
              : lesson.id
          )
          .collection("scores")
          .where("approved", "==", true)
          .get()
          .then((docs) => {
            console.log(docs.size);
            return docs.docs.filter((doc) => doc.data().lessonGroupId).length;
          });
        return docSize;
      }
    },
    isThisSemesterLesson(lesson) {
      if (lesson.startEnd) {
        if (this.selectedHalfYear.yearId > 1) {
          if (
            (lesson.startEnd["semester-3"] &&
              lesson.startEnd["semester-3"].available) ||
            (lesson.startEnd["semester-4"] &&
              lesson.startEnd["semester-4"].available)
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        } else {
          if (
            (lesson.startEnd["semester-1"] &&
              lesson.startEnd["semester-1"].available) ||
            (lesson.startEnd["semester-2"] &&
              lesson.startEnd["semester-2"].available)
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        }
      } else {
        lesson.isNotThisSemester = false;
      }

      var isUsingSemester = false;
      lesson.byTeachers
        .filter((teacher) => teacher.teacherId == this.userData.id)
        .forEach((teacher) => {
          if (teacher.xSemester) {
            isUsingSemester = true;
          }
        });
      if (isUsingSemester) {
        lesson.byTeachers.forEach((teacher) => {
          if (this.selectedHalfYear.yearId == 1) {
            if (teacher.xSemester != 1 && teacher.xSemester != 2) {
              lesson.isNotThisSemester = true;
            }
          } else {
            if (teacher.xSemester != 3 && teacher.xSemester != 4) {
              lesson.isNotThisSemester = true;
            }
          }
        });
      }
    },
    async setupp(semesterChanged) {
      if (this.userData.school) {
        var query = this.userData.school.ref
          .collection("lessons-" + this.userData.school.currentYear)
          .where("deleted", "==", false);

        if (this.userData.role == "teacher") {
          query = query.where(
            "teacherRefs",
            "array-contains",
            this.userData.ref
          );
        }
        query = query.orderBy("courseInfo.COURSE_NAME", "asc");
        await query.onSnapshot(async (docs) => {
          this.totalLessonCounter = 0;
          var reset = false;
          this.loading = true;
          if (!this.lessons || semesterChanged) {
            reset = true;
            this.lessons = [];
          }
          docs.forEach(async (doc) => {
            var notFound = false;
            if (!reset) {
              var lesson = this.lessons.find((lessn) => lessn.id == doc.id);
            } else {
              lesson = doc.data();
              lesson.ref = doc.ref;
              lesson.id = doc.id;
            }
            if (!lesson) {
              lesson = doc.data();
              lesson.ref = doc.ref;
              lesson.id = doc.id;
              notFound = true;
            }
            this.isAllowedLesson(lesson);
            this.isThisSemesterLesson(lesson);
            console.log(lesson.isNotThisSemester);
            if (!lesson.isNotAllowed && !lesson.isNotThisSemester) {
              var byTeachers = [];
              lesson.byTeachers.forEach((teacher) => {
                if (
                  teacher &&
                  teacher.teacherId &&
                  byTeachers.find((tt) => tt.teacherId == teacher.teacherId) ==
                    undefined
                ) {
                  byTeachers.push(teacher);
                }
              });
              lesson.byTeachers = byTeachers;
              if (
                this.selectedHalfYear.yearId == 1 &&
                lesson.esisLessonType.esisLessonTypeId == 2 &&
                this.userData.school.id == "xUL4ue608cCItYWRaly2" //Сэтгэмж
              ) {
                var status = await this.checkIfTypeChanged(lesson, 2);
                console.log(status, lesson.courseInfo.COURSE_NAME);
                if (status > 0) {
                  lesson.esisLessonType.esisLessonTypeId = 1;
                }
              }

              if (
                // this.selectedHalfYear.yearId == 1 &&
                lesson.esisLessonType.esisLessonTypeId == 1 &&
                this.userData.school.id == "dYybsiHFDZ8zvT6D0gGj" //73
              ) {
                status = await this.checkIfTypeChanged(lesson, 1);
                console.log(status, lesson.courseInfo.COURSE_NAME);
                if (status > 0) {
                  lesson.esisLessonType.esisLessonTypeId = 2;
                }
              }
              this.totalLessonCounter++;
              if (
                !lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    this.userData.id
                ] &&
                lesson[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "Unelgee2Saved-" +
                    this.userData.id
                ] == false
              ) {
                lesson.classGroupStudents = await this.userData.school.ref
                  .collection("students-" + this.userData.school.currentYear)
                  .where(
                    "STUDENT_GROUP_ID",
                    "==",
                    Number(lesson.classGroupRefs[0].id)
                  )
                  .get()
                  .then((docs) => {
                    let list = [];
                    docs.forEach((doc) => {
                      let student = doc.data();
                      student.ref = doc.ref;
                      student.id = doc.id;
                      if (this.selectedHalfYear.yearId == 1) {
                        if (
                          !student.ACTION_DATE ||
                          new Date(student.ACTION_DATE) <= this.halfYearDate
                        ) {
                          if (!student.moved) {
                            list.push(student);
                          }
                        }
                      } else {
                        if (
                          !student.ACTION_DATE ||
                          new Date(student.ACTION_DATE) <= this.halfYear2Date
                        ) {
                          if (!student.moved) {
                            list.push(student);
                          }
                        }
                      }
                    });
                    return list;
                  });
                if (lesson.esisLessonType.esisLessonTypeId > 1) {
                  lesson.students = await this._findGroupStudents(lesson);
                } else {
                  lesson.students = lesson.classGroupStudents;
                }
                lesson.unelgee2Scores = await this.getUnelgeeData(lesson);
                this.$forceUpdate();
                var tmp = {};
                tmp[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "Unelgee2Saved-" +
                    this.userData.id
                ] = true;
                lesson.ref.update(tmp);
              } else {
                this.userData.school.ref
                  .collection("students-" + this.userData.school.currentYear)
                  .where(
                    "STUDENT_GROUP_ID",
                    "==",
                    Number(lesson.classGroupRefs[0].id)
                  )
                  .get()
                  .then(async (docs) => {
                    lesson.classGroupStudents = [];
                    docs.forEach((doc) => {
                      let student = doc.data();
                      student.ref = doc.ref;
                      student.id = doc.id;
                      if (this.selectedHalfYear.yearId == 1) {
                        if (
                          !student.ACTION_DATE ||
                          new Date(student.ACTION_DATE) <= this.halfYearDate
                        ) {
                          if (!student.moved) {
                            lesson.classGroupStudents.push(student);
                          }
                        }
                      } else {
                        if (
                          !student.ACTION_DATE ||
                          new Date(student.ACTION_DATE) <= this.halfYear2Date
                        ) {
                          if (!student.moved) {
                            lesson.classGroupStudents.push(student);
                          }
                        }
                      }
                    });
                    if (lesson.esisLessonType.esisLessonTypeId > 1) {
                      lesson.students = await this._findGroupStudents(lesson);
                    } else {
                      lesson.students = lesson.classGroupStudents;
                    }
                    // if (15 > docs.size) {
                    lesson.unelgee2Scores = await this.getUnelgeeData(lesson);
                    this.$forceUpdate();
                    // }
                  });
              }

              if (reset || notFound) {
                if (notFound) {
                  var lesson2 = this.lessons.find(
                    (lessn) => lessn.id == doc.id
                  );
                  if (lesson2) {
                    lesson2 = lesson;
                  }
                } else {
                  if (!this.lessons.find((lessn) => lessn.id == doc.id)) {
                    this.lessons.push(lesson);
                  }
                }
              }
            }
            this.$forceUpdate();
          });
          console.log(this.totalLessonCounter);
        });
      }
    },
  },
  created() {
    this.halfYearDate = new Date(
      new Date().getFullYear() +
        "-" +
        this.$store.state.calendarButez2[2].months[0].name +
        "-" +
        this.$store.state.calendarButez2[2].months[0].days[0].day
    );
    this.halfYear2Date = new Date(
      new Date().getFullYear() +
        "-" +
        this.$store.state.calendarButez2[3].months[
          this.$store.state.calendarButez2[3].months.length - 1
        ].name +
        "-" +
        this.$store.state.calendarButez2[3].months[
          this.$store.state.calendarButez2[3].months.length - 1
        ].days[
          this.$store.state.calendarButez2[3].months[
            this.$store.state.calendarButez2[3].months.length - 1
          ].days.length - 1
        ].day
    );
    this.preText =
      this.userData.school.currentYear +
      "-" +
      (this.userData.school.currentYear + 1);
    this.selectedHalfYear = {
      yearId: 2,
      name: this.preText + " xичээлийн жил, " + 2 + "-р xагас жил",
    };
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        try {
          var date = val.toDate();
        } catch {
          date = val;
        }

        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
